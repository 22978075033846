<template>
  <div class="work-show">
    <el-row class="row" >
      <el-col :span="3" :xl="2" :lg="3" class="key">运营位名称</el-col>
      <el-col :offset="1" :span="18" :lg="18" :xl="18" class="val">{{details.name}}</el-col>
    </el-row>
    <el-row class="row" v-if="details.range && Array.isArray(details.range) && details.range.length > 0 ">
      <el-col :span="3" :xl="2" :lg="3" class="key">校区</el-col>
      <el-col :offset="1" :span="18" :lg="18" :xl="18" class="val">{{details.school.join("，")}}</el-col>
    </el-row>
    <el-row class="row" >
      <el-col :span="3" :xl="2" :lg="3" class="key">用户类型</el-col>
      <el-col :offset="1" :span="18" :lg="18" :xl="18" class="val">{{details.role_text}}</el-col>
    </el-row>
    <el-row class="row" v-if="details.range && Array.isArray(details.range) && details.range.length > 0 ">
      <el-col :span="3" :xl="2" :lg="3" class="key">可见范围</el-col>
      <el-col :offset="1" :span="18" :lg="18" :xl="18" class="val" v-html="details.range.join('，')"></el-col>
    </el-row>
    <el-row class="row" >
      <el-col :span="3" :xl="2" :lg="3" class="key">内容类型</el-col>
      <el-col :offset="1" :span="18" :lg="18" :xl="18" class="val">{{details.content_type_text}}</el-col>
    </el-row>
    <el-row class="row" v-if="details.title != null && details.title !== ''">
      <el-col :span="3" :xl="2" :lg="3" class="key">标题</el-col>
      <el-col :offset="1" :span="18" :lg="18" :xl="18" class="val">{{details.title}}</el-col>
    </el-row>
    <el-row class="row" v-if="details.publisher != null && details.publisher !== ''">
      <el-col :span="3" :xl="2" :lg="3" class="key">发布人</el-col>
      <el-col :offset="1" :span="18" :lg="18" :xl="18" class="val">{{details.publisher}}</el-col>
    </el-row>
    <el-row class="row" v-if="details.is_reply_text != null && details.is_reply_text !== ''">
      <el-col :span="3" :xl="2" :lg="3" class="key">是否可回复</el-col>
      <el-col :offset="1" :span="18" :lg="18" :xl="18" class="val">{{details.is_reply_text}}</el-col>
    </el-row>
    <el-row class="row" v-if="details.image != null && details.image !== ''">
      <el-col :span="3" :xl="2" :lg="3" class="key">图片</el-col>
      <el-col :offset="1" :span="18" :lg="18" :xl="18" class="val">
        <img :src="details.image" alt="">
      </el-col>
    </el-row>
    <el-row class="row" >
      <el-col :span="3" :xl="2" :lg="3" class="key">{{details.content_type === 2?"链接地址":"内容"}}</el-col>
      <el-col :offset="1" :span="18" :lg="18" :xl="18" class="val" v-html='details.content' v-if="details.content_type !== 2">{{details.content}}</el-col>
      <el-col :offset="1" :span="18" :lg="18" :xl="18" class="val" v-else v-html="`<a href='${details.content}' target='_blank'>${details.content}</a>`">{{details.content_type !== 2}}</el-col>
    </el-row>
    <el-row class="row" >
      <el-col :span="3" :xl="2" :lg="3" class="key">开始展示时间</el-col>
      <el-col :offset="1" :span="18" :lg="18" :xl="18" class="val">{{details.start_at}}</el-col>
    </el-row>
    <el-row class="row" >
      <el-col :span="3" :xl="2" :lg="3" class="key">结束展示时间</el-col>
      <el-col :offset="1" :span="18" :lg="18" :xl="18" class="val">{{details.end_at}}</el-col>
    </el-row>
    <el-row class="row" v-if="details.version != null && details.version !== ''">
      <el-col :span="3" :xl="2" :lg="3" class="key">学生端最低版本</el-col>
      <el-col :offset="1" :span="18" :lg="18" :xl="18" class="val">{{details.version}}</el-col>
    </el-row>
    <el-row class="row" >
      <el-col :span="3" :xl="2" :lg="3" class="key">运营位位置</el-col>
      <el-col :offset="1" :span="18" :lg="18" :xl="18" class="val">{{details.position}}</el-col>
    </el-row>
    <el-row class="row" >
      <el-col :span="3" :xl="2" :lg="3" class="key">当前状态</el-col>
      <el-col :offset="1" :span="18" :lg="18" :xl="18" class="val">{{details.status_text}}</el-col>
    </el-row>
    <el-row class="row" >
      <el-col :span="3" :xl="2" :lg="3" class="key">创建人</el-col>
      <el-col :offset="1" :span="18" :lg="18" :xl="18" class="val">{{details.creator}}</el-col>
    </el-row>
    <el-row class="row" >
      <el-col :span="3" :xl="2" :lg="3" class="key">创建时间</el-col>
      <el-col :offset="1" :span="18" :lg="18" :xl="18" class="val">{{details.created_at}}</el-col>
    </el-row>

    <el-row>
      <el-col :span="3" :xl="2" :lg="3" class="key">
        <el-button type="primary" style="margin-top: 40rem;" @click="$router.back();"> 返回</el-button>
      </el-col>
    </el-row>

  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》 ';
export default {
	_config:{"route":{"path":"look","meta":{"title":"查看"}}},
  // import 引入的组件需要注入到对象中才能使用
  components: {},
  props: {},
  data() {
    // 这里存放数据
    return {
      details:{

      }
    }
  },
  // 计算属性 类似于 data 概念
  computed: {},
  // 监控 data 中的数据变化
  watch: {},
  // 方法集合
  methods: {
    getData(){
      this.$_axios2.get("/api/operation/index/"+this.$route.query.id).then(res=>{
        let {data} = res.data;
        if (data.content_type !== 2){
          let con = data.content;
          con = con.replace(/<img/g,"<img style='max-width:100%;'");
          data.content = con;
          console.log(con);
        }
        this.details = data;

      })
    }
  },
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created() {
  },
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    this.getData();
  },
  beforeCreate() {
  },
  beforeMount() {
  }, // 生命周期 - 挂载之前
  beforeUpdate() {
  }, // 生命周期 - 更新之前
  updated() {
  }, // 生命周期 - 更新之后
  beforeDestroy() {
  }, // 生命周期 - 销毁之前
  destroyed() {
  }, // 生命周期 - 销毁完成
  activated() {
  } // 如果页面有 keep-alive 缓存功能,这个函数会触发
}
</script>
<style lang="scss" scoped>
.work-show{
  background-color: white;
  padding: 35rem 50rem;
  .row{
    margin-bottom: 30rem;
  }
  .key{
    text-align: right;
    color: #666;
    font-size: 14rem;
  }
  .val{
    color: #000;
    font-size: 14rem;
    margin-left: 12rem;
  }
}
</style>
